@import '@matillion/component-library/dist/assets/styles/colours';

.Dashboard {
  &__Intro {
    padding: 3rem;
  }

  &__Code {
    white-space: pre-wrap;
  }

  &__Value {
    padding-right: 8px;
  }
}
